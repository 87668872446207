/**, *:before, *:after {
  -webkit-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-text-size-adjust: none;
}*/
.pageContent.expanded{
    margin-left: 83px;
}
.pageContent{
    transition: all 0.4s ease-in-out;
    margin-bottom:60px;
}

.welcomeButton{position:relative;z-index:2;}
.welcomeReward,
.welcomeReward div {position:absolute;top:200px;bottom:0;z-index:0;width:100%;text-align: center;}